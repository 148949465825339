import {
  API_KEY, API_SECRET, IProviderInputInterface, SAME_VALUE,
} from './payment-provider-types';

export const voltConfigs: IProviderInputInterface[] = [
  {
    name: 'username',
    label: 'Username',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to Volt Fusebox, Navigate to Dashboard > Configuration > Customers tab > Select your Customer name, go to the API Access tab, and generate/copy your Username under Volt Credentials',
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Log in to Volt Fusebox, Navigate to Dashboard > Configuration > Customers tab > Select your Customer name, go to the API Access tab, and generate your Password under Volt Credentials',
  },
  {
    name: 'publicKeyId',
    label: 'Public Key ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: false,
    infoGuide: 'Public key Id is not mandatory and is only used in processing Refunds. Leave it blank if not needed. However, To generate it, Log in to Volt Fusebox, Navigate to Dashboard > Configuration > Customers tab > Select your Customer name, go to the API Access tab, and generate a new Key Pair under Signature Keys.',
  },
  {
    name: 'privateKey',
    label: 'Private Key',
    type: 'password',
    keyType: API_SECRET,
    isRequired: false,
    infoGuide: 'Private Key is not mandatory and is only used in processing Refunds. Leave it blank if not needed. To generate it, Log in to Volt Fusebox, Navigate to Dashboard > Configuration > Customers tab > Select your Customer name, go to the API Access tab, and generate a new Key Pair under Signature Keys.',
  },
];

export const visaCardConfigs = [
  {
    name: 'merchantId',
    label: 'Merchant ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Merchant ID will be displayed on the header, once we login to the portal.',
  },
  {
    name: 'apiKey',
    label: 'Key',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Log in to CyberSource Visa Portal, On the Dashboard > Click on \'+ Generate New Key\' > Create Key > Select the REST-Shared Secret under REST API\'s and click on the \'Generate Key\' button at the bottom.',
  },
  {
    name: 'sharedSecret',
    label: 'Shared Secret',
    type: 'password',
    keyType: API_SECRET,
    infoGuide: 'Log in to CyberSource Visa Portal, On the Dashboard > Click on \'+ Generate New Key\' > Create Key > Select the REST-Shared Secret under REST API\'s and click on the \'Generate Key\' button at the bottom.',
  },
];

export const tabbyConfigs: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your Api Key, contact your Tabby account manager or access them directly from the Dashboard, depending on your integration type.',
  },
  {
    name: 'merchantCode',
    label: 'Merchant Code',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Merchant Code represents a merchant country or a specific store within the country',
  },
  {
    name: 'apiSecret',
    label: 'Api Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'To obtain your Api secret, contact your Tabby account manager or access them directly from the Dashboard, depending on your integration type.',
  },
];

export const grabConfigs: IProviderInputInterface[] = [
  {
    name: 'partnerId',
    label: 'Partner ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter partner ID',
  },
  {
    name: 'clientId',
    label: 'Client ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter client ID',
  },
  {
    name: 'merchantId',
    label: 'Merchant ID',
    type: 'text',
    keyType: API_KEY,
    infoGuide: 'Please enter merchant ID',
  },
  {
    name: 'partnerSecret',
    label: 'Partner Secret',
    type: 'password',
    keyType: API_SECRET,
    infoGuide: 'Please enter partner secret',
  },
  {
    name: 'clientSecret',
    label: 'Client Secret',
    type: 'password',
    keyType: API_SECRET,
    infoGuide: 'Please enter client secret',
  },
];

export const zipConfig: IProviderInputInterface[] = [
  {
    name: 'Api-Key',
    label: 'API Key',
    type: 'password',
    keyType: SAME_VALUE,
    infoGuide: 'Please Enter credentials provided by provider',
  },
];

export const fiservCardsAuConfig: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'API Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to the Fiserv Portal and navigate to \'API Key\' to copy/create your API key',
  },
  {
    name: 'storeId',
    label: 'Store ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain the Store ID, please contact the Fiserv Support Team',
  },
  {
    name: 'sharedSecret',
    label: 'Shared Secret',
    type: 'password',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain the Shared Secret, please contact the Fiserv Support Team',
  },
  {
    name: 'apiSecret',
    label: 'API Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Log in to the Fiserv Portal and navigate to \'Secret Key\' to copy/create your Secret key.',
  },
];

export const tamaraConfig: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'password',
    keyType: SAME_VALUE,
    isRequired: true,
    infoGuide: 'Please enter Api Key',
  },
];

export const crediteaConfig: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'password',
    keyType: SAME_VALUE,
    isRequired: true,
    infoGuide: 'Please enter Api Key',
  },
];

export const hummConfigs: IProviderInputInterface[] = [
  {
    name: 'apiKey',
    label: 'Api Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Please enter Api Key',
  },
  {
    name: 'storeId',
    label: 'Store ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Please enter valid Store ID',
  },
  {
    name: 'apiSecret',
    label: 'Api Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Please enter Api Secret',
  },
  {
    name: 'refreshToken',
    label: 'Refresh Token',
    type: 'text',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Please enter Refresh Token',
  },
];

export const contactEgConfig: IProviderInputInterface[] = [
  {
    name: 'api_key',
    label: 'Api Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to Paymob portal, Navigate to Settings > Account info to View / Recreate your API key',
  },
  {
    name: 'publicKey',
    label: 'Public Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to Paymob portal, Navigate to Settings > Account info to View / Recreate your Public Key',
  },
  {
    name: 'integrationId',
    label: 'Integration Id',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Log in to Paymob portal, Navigate to Developers > Payment integrations to obtain your Integration Id',
  },
  {
    name: 'apiSecret',
    label: 'Secret Key',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Log in to Paymob portal, Navigate to Settings > Account info to View / Recreate your Secret Key',
  },
];

export const nuapayConfigs: IProviderInputInterface[] = [
  {
    name: 'orgId',
    label: 'Organisation Id',
    type: 'text',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'To obtain your Organisation Id, contact your Nuapay account manager or access them directly from the Dashboard.',
  },
  {
    name: 'apiKey',
    label: 'API Key',
    type: 'password',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your Api Key, contact your Nuapay account manager or access them directly from the Dashboard.',
  },
];

export const paybyConfigs: IProviderInputInterface[] = [
  {
    name: 'partnerId',
    label: 'Partner ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'Partner ID will be displayed on the top right corner, once we login to the portal.',
  },
  {
    name: 'privateKey',
    label: 'Private Key',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'Log in to PayBy Portal, Navigate to Settings > API management > Click on Key Generation Tool > Click on Generate Private Key.',
  },
];

export const trustlyConfigs: IProviderInputInterface[] = [
  {
    name: 'username',
    label: 'Username',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
  },
  {
    name: 'password',
    label: 'Password',
    type: 'password',
    keyType: API_KEY,
    isRequired: true,
  },
  {
    name: 'privateKey',
    label: 'Private Key',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
  },
];

export const dlocalConfigs: IProviderInputInterface[] = [
  {
    name: 'xTransKey',
    label: 'xTransKey',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your xTransKey, Login to Dlocal Portal, Navigate to Settings > Integration > Under Credentials you can find xTransKey',
  },
  {
    name: 'xloginKey',
    label: 'xloginKey',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your xloginKey, Login to Dlocal Portal, Navigate to Settings > Integration > Under Credentials you can find xloginKey',
  },
  {
    name: 'Secret Key',
    label: 'API Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'To obtain your SecretKey, Login to Dlocal Portal, Navigate to Settings > Integration > Under Credentials you can find SecretKey',
  },
];

export const shopeepayConfigs: IProviderInputInterface[] = [
  {
    name: 'clientKey',
    label: 'Client Key',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your Client Key, contact your Shopeepay account manager.',
  },
  {
    name: 'merchantExernalId',
    label: 'Merchant External ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your Merchant External ID, contact your Shopeepay account manager.',
  },
  {
    name: 'storeExernalId',
    label: 'Store External ID',
    type: 'text',
    keyType: API_KEY,
    isRequired: true,
    infoGuide: 'To obtain your Store External ID, contact your Shopeepay account manager.',
  },
  {
    name: 'clientSecret',
    label: 'Client Secret',
    type: 'password',
    keyType: API_SECRET,
    isRequired: true,
    infoGuide: 'To obtain your Client Secret, contact your Shopeepay account manager.',
  },
];
